<template>
  <figure>
    <slot name="image"/>
    <figcaption>
      <div>
        <slot name="caption"/>
      </div>
    </figcaption>
  </figure>
</template>

<style lang="scss">
figure {
  position: relative;
  margin: 0;
  margin-bottom: -4px;

  img {
    min-height: 235px;
    max-height: 70vh;
    width: 100%;
    object-fit: cover;
  }

  figcaption {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    display: grid;
    padding: 24px;
    margin: auto;

    div {
      color: white;
      display: grid;
      padding: 20px 36px;
      background-color: rgba(0,0,0,0.7);
      border-radius: 0 0 var(--rc) 0;
      box-shadow: var(--shadow);
      margin: auto;
    }
  }
}
</style>
