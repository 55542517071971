<template>
  <div class="home">
    <intersection-animation>
      <div class="top grid fit-400 fit-350-phone">
        <div class="landing-text">
          <h1>DAN CHITWOOD: Registered Landscape Architect</h1>
          <span class="accent-dark">Dreams become reality when you start with a master plan</span>
          <p>See how I can transform your next project.</p>
          <div class="landing-buttons">
            <router-link to="contact"><button>Get Started</button></router-link>
            <router-link to="consultation-and-design" class="flair">See the process</router-link>
          </div>
        </div>
        <div class="landing-image-container rounded-bottom-left">
          <responsive-image
            class="landing-image rounded-bottom-left"
            alt="Waterfront pool and hot-tub combo overlooking a river inlet.
              The patio around the pool is stone tile and the surrounding area around the pool
              is filled with shrubs and small trees. There are planters with bushes in front
              of a fence along the river."
            file="rogers-waterfront"
            :width="500"
            :height="400"
          />
        </div>
      </div>
    </intersection-animation>
    <section class="content-block">
      <intersection-animation>
        <p class="text-block">
          If you're looking to add some excitement to your yard, if you're looking to enhance
          the image of your business property, if you just find the task of landscaping to be
          daunting, then I can help you.
        </p>
        <div class="lg-padded-grid grid fit-300 fit-150-phone">
          <responsive-image
            alt="A back-patio pool sites on a raised site over rolling hills of trees. The patio
              is made of stone tile and around the patio is loose river rocks with shrubs and trees
              in front of a fence strategically placed to promote the view."
            file="trum-pool"
            :width="500"
            :height="400"
          />
          <responsive-image
            alt="A stone walkway made up of 4 square stone blocks in groups making up each step.
              The area surrounding the path is made up of loose river rock. Green shrubbery is
              coming up through the stone on the right."
            file="river-rock-walk"
            :width="500"
            :height="400"
          />
          <responsive-image
            alt="An L shaped custom stone raised pool overlooks the mountains of the Roanoke valley.
              The pool stone is a dark tan and polished to a smooth finish."
            file="boaz"
            :width="500"
            :height="400"
          />
          <responsive-image
            class="limited-height"
            alt="A backyard landscaping display featuring many different plants in artistic
              arrangements with a statue raised on stones overlooking a small pond with a
              wooden bride across."
            file="zen-pond"
            :width="500"
            :height="400"
          />
          <responsive-image
            class="limited-height"
            alt="A stone pool with a small waterfall feeding in from the above rock
              garden. Behind the pool is a massive custom stone retaining wall to match."
            file="erath-back"
            :width="500"
            :height="400"
          />
          <responsive-image
            class="limited-height"
            alt="An asiatic lily growing in front of beautiful arbor arches holding vines."
            file="asiatic-lilly"
            :width="500"
            :height="400"
          />
        </div>
      </intersection-animation>
    </section>
    <!-- <section class="video-split">
      <intersection-animation>
        <div class="left accent">
          <h2>See the Process</h2>
          <p>
            Excepteur sint occaecat cupidatat non proident,
            sunt in culpa qui officia deserunt mollit anim id est laborum.
            Excepteur sint occaecat cupidatat non proident,
            sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <div>
            <router-link to="contact"><button>Find Out How</button></router-link>
          </div>
        </div>
        <div class="right contrast">
          <h3 id="demo">How It's Done</h3>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/BHACKCNDMW8" frameborder="0" allow="acceleromter; autoplay; encrypted-media: gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </intersection-animation>
    </section> -->
    <section class="muted services">
      <intersection-animation>
        <h2>What I Do</h2>
        <div class="grid fit-300 content-block">
          <article class="accent rounded-bottom-left">
            <h3>Consultation</h3>
            <hr>
            <p>
              Start your next project with a consultation by an expert.
            </p>
            <p>
              We will consider all options and discuss objectives and concerns.
            </p>
            <p>Get the answers to your questions in writing or in the form of a drawing.</p>
            <p>
              Talk is cheap but construction is not. Having these issues resolved prior
              to construction can save you time, money, and headaches.
            </p>
            <router-link class="flair" to="/contact">
              Call me today to set up your consultation
            </router-link>
          </article>
          <article class="accent rounded-bottom-left">
            <h3>Grading &amp; Drainage Planning</h3>
            <hr>
            <p>
              Your plan will be better coordinated with walks, patios, lighting,
              irrigation, drainage, landscaping, etc. built into the master plan rather
              than being an afterthought.
            </p>
            <responsive-image
              alt="A stone irrigation solution divides a grass backyard from
                a garden."
              file="stone-ditch"
              :width="300"
              :height="200"
            />
          </article>
          <article class="contrast rounded-bottom-left">
            <h3>Residential Design</h3>
            <responsive-image
              alt="An arbor hangs over the front windows of a brick building. On the
                right side it supports a hanging porch swing and across the top of the
                arbor is a large leafy fern."
              file="porch-swing"
              :width="300"
              :height="200"
            />
            <hr>
            <p>
              Whether you just purchased your first house or you just made your last
              mortgage payment, you may feel like you want to spruce your yard up.
              I have experience in residential landscape architecture that can benefit you.
            </p>
          </article>
        </div>
        <router-link to="/consultation-and-design#heading">
          <button>Learn More</button>
        </router-link>
      </intersection-animation>
    </section>
    <section class="contrast">
      <intersection-animation>
        <h2>My Yard</h2>
        <div class="accent-block padded yard">
          <responsive-image
            class="rounded-bottom-left"
            alt="A pavilion stlye gazebo stands in the middle of a backyard surrounded by plants.
              Hanging from the gazebo are several ferns and an outdoor lounge chair. Stone
              pavers cover the ground under the gazebo and support a patio set."
            file="backyard-canopy-side"
            :width="800"
            :height="450"
          />
          <router-link class="flair" to="/visit-my-yard">See more</router-link>
        </div>
      </intersection-animation>
    </section>
    <section>
      <intersection-animation>
        <caption-image>
          <template v-slot:image>
            <img
              loading="lazy"
              alt="A fenced-in pool with uniform stone block surrounding and accenting tiles
                inside. Across from the pool is a raised putting green supported by a stone
                retaining wall. Downhill from that is a small display of trees and bushes
                along the fence."
              src="@/assets/photos/original/austin-pool.jpg"
            >
          </template>
          <template v-slot:caption>
            <h2>Residential Pools</h2>
            <p>
              If you're considering installing or remodeling a
              <br>
              pool, take a look at how I can help you.
            </p>
          </template>
        </caption-image>
      </intersection-animation>
    </section>
    <section class="accent content-block">
      <intersection-animation>
        <div class="grid fit-400 fit-300-phone">
          <div class="accent-block padded">
            <h2>Designing Landscapes in Roanoke, VA for {{ yearsExperience }} Years</h2>
            <p class="text-block">
              I have the knowledge and experience to create landscape designs
              that are visually pleasing, functional, and cost effective.
              <br>
              <br>
              When you work with me, we can turn your ideas into reality.
              <br>
              <br>
              From site planning to construction drawings, cost estimates
              to consultation, give me a call today to get your project started.
            </p>
            <hr>
            <p>Phone:</p>
            <a class="flair" href="tel:+15403149040">(540) 314-9040</a>
            <p>Email:</p>
            <a class="flair" href="mailto:chitwoodlandscape@gmail.com">
              chitwoodlandscape@gmail.com
            </a>
          </div>
          <div class="accent-block">
            <responsive-image
              class="rounded-bottom-left"
              alt="A tightly designed outdoor entertaining space. A raised rear deck overlooks
                a stone patio equiped with a hot-tub, a fire pit, and plenty of outdoor seating.
                A wooden porch swing is suspended from the deck. Under the deck's right supporting
                leg is a small garden."
              file="vaughn-back-patio"
              :width="700"
              :height="400"
            />
            <router-link to="contact">
              <button>Get a quote today</button>
            </router-link>
          </div>
        </div>
      </intersection-animation>
    </section>
  </div>
</template>

<script>
import CaptionImage from '@/components/CaptionImage';
import ResponsiveImage from '@/components/ResponsiveImage';

const firstYear = 1979;
const currentYear = new Date().getFullYear();
const yearsExperience = currentYear - firstYear;

export default {
  name: 'Home',
  components: {
    CaptionImage,
    ResponsiveImage,
  },
  data: () => ({
    yearsExperience,
  }),
};
</script>

<style lang="scss">
@import "~include-media/dist/_include-media.scss";

.home {
  position: relative;
  display: flex;
  flex-direction: column;

  .landing-text {
    position: relative;
    margin-top: 156px;
    text-align: left;
    padding: 12px 24px;

    h1 {
      font-size: 1.4rem;
    }

    span {
      font-size: 2em;
      font-family: "Ubuntu", sans-serif;
      text-transform: uppercase;
    }

    .landing-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      button {
        margin-bottom: 0;
        margin-right: 2rem;
      }
    }

    @include media("<header-break") {
      margin-top: 100px;
      text-align: center;

      .landing-buttons {
        justify-content: center;
      }
    }

    @include media(">1500px") {
      margin: auto;
      padding: 12px 48px;
      text-align: center;
      font-size: x-large;

      .landing-buttons {
        justify-content: center;
      }
    }
  }

  @include media(">=header-break") {
    .landing-image-container {
      background:
        linear-gradient(
          to bottom,
          rgba(0,0,0,0.8) 0%,
          rgba(0,0,0,0.6) 20%,
          rgba(0,0,0,0) 50%,
          rgba(0,0,0,0) 100%
        );
    }
  }

  .landing-image {
    position: relative;
    max-height: 55vh;
    z-index: -1; // set image behind gradient above it
  }

  .content-block {
    padding-bottom: 28px;
  }

  .limited-height {
    @include media("<=2000px") {
      max-height: 350px;
    }
  }

  .text-block {
    padding: 12px 20%;

    @include media(">1500px") {
      font-size: larger;
    }
  }

  .accent-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.padded {
      padding: 24px;
      align-items: center;
    }

    img {
      max-height: 25rem;
    }

    a {
      button {
        margin-top: 2rem;
      }
    }
  }

  .video-split {
    position: relative;
    margin: 48px 0;

    .left, .right {
      height: 410px;
    }

    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 16px 56% 16px 48px;
      clip-path: polygon(62% 100%, 54% 0, 0 0, 0 100%);

     @include media("<=tablet") {
        padding: 16px;
        clip-path: none;
      }
    }
    .right {
      position: absolute;
      right: 0;
      left: 10%;
      top: -15px;

      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px 48px 16px 44%;
      clip-path: polygon(38.5% 0, 100% 0, 100% 100%, 49% 100%);

      @include media("<=tablet") {
        position: relative;
        padding: 16px;
        left: 0;
        clip-path: none;
      }
    }
  }

  .services {
    article {
      justify-content: space-between;
      align-items: center;
      text-align: center;
      padding: 16px;
      margin: 0 auto;
      max-width: 400px;
      box-shadow: var(--shadow);

      img {
        width: unset;
        height: unset;
        max-height: 30vh;
        min-height: 200px;
      }

      p {
        text-align: center;
      }

      .flair {
        &:before {
          top: 36px;
        }
      }
    }
  }

  .yard {
    img {
      max-height: 70vh;
    }

    a {
      font-size: large;
      margin-top: 4rem;
      align-self: flex-end;
    }
  }
}
</style>
