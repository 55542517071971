<template>
  <picture>
    <!-- TODO: loop? -->
    <!-- TODO: min quality? -->
    <!-- TODO: should sm be 600px? xxl? -->
    <source
      :srcset="require(`@/assets/photos/xl/${file}.webp`)"
      media="(min-width: 1200px)"
      type="image/webp"
    >
    <source
      :srcset="require(`@/assets/photos/xl/${file}.jpg`)"
      media="(min-width: 1200px)"
      type="image/jpg"
    >

    <source
      :srcset="require(`@/assets/photos/lg/${file}.webp`)"
      media="(min-width: 900px)"
      type="image/webp"
    >
    <source
      :srcset="require(`@/assets/photos/lg/${file}.jpg`)"
      media="(min-width: 900px)"
      type="image/jpg"
    >

    <source
      :srcset="require(`@/assets/photos/md/${file}.webp`)"
      media="(min-width: 600px)"
      type="image/webp"
    >
    <source
      :srcset="require(`@/assets/photos/md/${file}.jpg`)"
      media="(min-width: 600px)"
      type="image/jpg"
    >

    <source
      :srcset="require(`@/assets/photos/sm/${file}.webp`)"
      media="(max-width: 320px)"
      type="image/webp"
    >
    <source
      :srcset="require(`@/assets/photos/sm/${file}.jpg`)"
      media="(max-width: 320px)"
      type="image/jpg"
    >

    <img
      loading="lazy"
      :class="this.class"
      :width="width"
      :height="height"
      :alt="alt"
      :src="require(`@/assets/photos/lg/${file}.jpg`)"
    >
  </picture>
</template>

<script>
export default {
  props: {
    class: String,
    alt: String,
    file: String,
    width: Number,
    height: Number,
  },
};
</script>

<style lang="scss">

</style>
